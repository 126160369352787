// ajax 配合JQ3 引入設置
// $(document).ready(function () {
//   $.ajax({
//     url: "ajax/_header.html",
//     method: "GET",
//     dataType: "html"
//   }).done(
//     function (data) {
//       $("#header").html(data);
//     }
//   );
//   $("#footer").load("ajax/_footer.html");
// });

function toolsListener() {
  window.addEventListener("keydown", function (e) {
    if (e.keyCode === 9) {
      document.body.classList.remove("js-useMouse");
      document.body.classList.add("js-useKeyboard");
    }
  });
  window.addEventListener("mousedown", function (e) {
    document.body.classList.remove("js-useKeyboard");
    document.body.classList.add("js-useMouse");
  });
}

function asideMenuAutoActive() {
  var triggerEl = document.querySelectorAll('.c-listGroup>.list-group-item');
  var hashUrl = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
  if (triggerEl) {
    triggerEl.forEach(function (item) {
      var checkHref = item.querySelector('.list-group-link').href;
      var substringHref = checkHref.substring(checkHref.lastIndexOf("/") + 1);
      if(hashUrl.match(substringHref)){
        item.classList.add('active');
      }else{
        item.classList.remove('active');
      }
    });

  }
}

//呼叫function-網頁載入完成後
$(document).ready(function () {
  toolsListener();
  asideMenuAutoActive();
});
//呼叫function-視窗大小變更
$(window).resize(function () {});
//呼叫function-捲動
$(window).scroll(function () {});